<template>
  <!--
    Used to manage the devices of a certain installation
  -->
  <div>
    <Portlet
      :title="$t('localDeviceManagementComponent.localDeviceManagement')"
      icon="hdd"
      class="localDeviceManagement"
    >
      <template slot="buttons">
        <a
          href="#"
          m-portlet-tool="reload"
          class="m-portlet__nav-link m-portlet__nav-link--icon"
          @click.prevent="getData()"
        >
          <font-awesome-icon
            :class="[{ 'fa-spin' : reloading || loading }]"
            icon="sync-alt"
          />
        </a>
        <div class="btn-group btn-group-sm ml-2">
          <router-link
            :to="`/installation/${ installationId }/devices/ipscanner${laneNumber ? `/${laneNumber}` : ''}`"
            class="btn btn-secondary btn-sm"
          >
            <font-awesome-icon
              class="gray mr-1"
              icon="search-location"
            />
            <span>
              IP Scanner
            </span>
          </router-link>
          <button
            class="btn btn-sm btn-secondary"
            @click="scanDevices()"
          >
            <font-awesome-icon
              class="gray mr-2"
              icon="search"
            />
            <span>{{ $t('scan') }}</span>
          </button>
          <button
            class="btn btn-sm btn-secondary d-none d-sm-inline-block"
            @click="clearDevices()"
          >
            <font-awesome-icon
              class="mr-2 gray"
              icon="trash"
            />
            <span>{{ $t('clear') }}</span>
          </button>
          <button
            slot="button"
            class="btn btn-sm btn-secondary d-none d-sm-inline-block"
            @click="openConfirmModal()"
          >
            <font-awesome-icon
              class="mr-2"
              icon="redo-alt"
            />{{ 'Restart' }}
          </button>
        </div>
      </template>

      <LoadingPlaceholder v-if="loading" />

      <template v-else>
        <Grid
          :ref="kgm_ref"
          :style="{height: 'auto'}"
          :data-items="kgm_computedGridItems(devices)"
          :columns="kgm_responsiveColumns()"
          :filterable="false"
          :pageable="kgm_pagable"
          :page-size="kgm_take"
          :skip="kgm_skip"
          :take="kgm_take"
          :total="kgm_allGridItems(devices)"
          :sortable="{
            allowUnsort: kgm_allowUnsort,
            mode: kgm_sortMode
          }"
          :sort="kgm_sort"
          selected-field="selected"
          @rowclick="kgm_gridOnRowClick"
          @filterchange="kgm_gridFilterChange"
          @pagechange="kgm_gridPageChange"
          @sortchange="kgm_gridSortChange"
        >
          <template
            slot="dateTemplate"
            slot-scope="{props}"
          >
            <td>
              <span v-tooltip="dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
            </td>
          </template>
        </Grid>
      </template>
    </Portlet>
    <SweetModal
      ref="confirmRestart"
      title="Restart?"
      icon="warning"
      blocking
      class="overflowHidden"
    >
      <p>{{ $t('sureToRestart', {'0' : 'Device Management'}) }}</p>
      <button
        slot="button"
        class="btn btn-secondary float-left mb-3"
        @click="$refs.confirmRestart.close()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />{{ $t('cancel') }}
      </button>
      <button
        slot="button"
        class="btn btn-primary float-right mb-3"
        @click="restartDeviceManagement()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="redo-alt"
        />{{ 'Restart' }}
      </button>
      <div class="clearfix" />
    </SweetModal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "LocalDeviceManagement",
  components: {
    SweetModal
  },
  mixins: [
    kendoGridMixin,
    dateTimeMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      loading: true,
      reloading: false,
      devices: null,
      dataInterval: null,
      cancelDataSource: null,
      kgm_take: 5,
      kgm_sort: [
        {
          "field": "discoveryDate",
          "dir": "desc"
        }
      ],
      kgm_columns: [
        {
          field: 'discoveryDate',
          filterable: true,
          filter: 'date',
          title: this.$t('localDeviceManagementComponent.discoveryDate'),
          cell: 'dateTemplate',
          hideOn: ['lgDown']
        },
        {
          field: 'discoveredDeviceType',
          filterable: true,
          filter: 'text',
          title: this.$t('localDeviceManagementComponent.discoveredDeviceType')
        },
        {
          field: 'ipAddress',
          filterable: true,
          filter: 'text',
          title: this.$t('localDeviceManagementComponent.ipAddress')
        },
        {
          field: 'manufacturer',
          filterable: true,
          filter: 'text',
          title: this.$t('localDeviceManagementComponent.manufacturer'),
          hideOn: ['mdDown']
        },
        {
          field: 'model',
          filterable: true,
          filter: 'text',
          title: this.$t('localDeviceManagementComponent.model'),
          hideOn: ['xsDown']
        },
        {
          field: 'serialNumber',
          filterable: true,
          filter: 'text',
          title: this.$t('localDeviceManagementComponent.serialNumber'),
          hideOn: ['smDown']
        }
      ]
    }
  },
  created () {
    this.createDataInterval();
  },
  beforeDestroy () {
    this.clearDataInterval();
    this.cancelDataRequest();
  },
  beforeRouteLeave (to, from, next) {
    this.clearDataInterval();
    this.cancelDataRequest();
    next()
  },
  methods: {
    openConfirmModal () {
      this.$refs.confirmRestart.open();
    },
    createDataInterval () {
      this.getData();
      this.dataInterval = window.setInterval(this.getData, 10000);
    },
    clearDataInterval () {
      window.clearInterval(this.dataInterval);
      this.dataInterval = null;
    },
    cancelDataRequest () {
      if (this.cancelDataSource) {
        this.cancelDataSource.cancel();
      }
    },
    clearDevices () {
      let url = `/DeviceManagement/ClearDevices?installationId=${ this.installationId }`;
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.delete(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
        });
    },
    scanDevices () {
      let url = `/DeviceManagement/ScanDevices?installationId=${ this.installationId }`;
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status != 200) {
            return;
          }
        });
    },
    getData () {
      let url = `/DeviceManagement/GetDevices?installationId=${ this.installationId }`;
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.reloading = true;
      this.cancelDataRequest();
      this.cancelDataSource = this.axios.CancelToken.source();
      let requestConfig = { cancelToken: this.cancelDataSource.token };
      this.axios.get(url, requestConfig)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          this.devices = response.data;
        })
        .catch(() => {
          if (this.axios.isCancel()) {
            return;
          }
          this.clearDataInterval();
        })
        .finally(() => {
          this.loading = false;
          this.reloading = false;
        });
    },
    restartDeviceManagement () {
      this.$refs.confirmRestart.close();
      this.axios.get(`/Endpoint/GetTrackerDevice?installationId=${this.installationId}&componentType=DeviceManagement&laneNumber=${this.laneNumber}`)
        .then((response) => {
          if (response) {
            let commandRequest = {
              'Command': 'restartDeviceManagement',
              'Args': ''
            };
            if (response.data && response.data.uuid)
            {
              this.axios.post(`/CentralDeviceManagement/ExecuteCommand?deviceUuid=${ response.data.uuid }`, commandRequest)
                .then((response) => {
                  this.$snotify.info(response.data.output);
                })
                .catch(() => {
                   this.$snotify.error("Can not restart Device Management");
                });
            }
        }
        });
    },
  }
}
</script>
